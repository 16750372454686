<template>
  <div>
    <div class="mb-8">
      <page-header :heading="pageHeading" :sub-heading="pageSubHeading"></page-header>
    </div>
    <h2 class="mb-4"
        v-text="heading"
    ></h2>
    <data-table
      table-name="management.table"
      :data-table="dataTable"
      :actions="actions"
      :search-action="searchAction"
    >
      <template #item.id="{ item }">
        <copy :text="item.id" :display="shortenUuid(item.id)"></copy>
      </template>

      <template #item.username="{ item }">
        <copy :text="item.username"></copy>
      </template>

      <template #item.email="{ item }">
        <copy :text="item.email"></copy>
      </template>

      <template #item.createdOn="{ item }">
        <formatted-date :date="item.createdOn"></formatted-date>
      </template>
    </data-table>
    <div
      class="mt-8 d-flex justify-end"
    >
      <v-btn
        @click="addUserDialog = true"
        color="green"
        class="white--text"
        v-text="createUserButtonText"
      ></v-btn>
    </div>
    <confirmation-dialog
      v-model="deleteDialog"
      :title="deleteDialogTitle"
      :text="deleteDialogText"
      :cancel="deleteDialogCancelText"
      :confirm="deleteDialogConfirmText"
      confirm-color="red"
      @cancel="resetDeleteDialog"
      @confirm="confirmDelete"
    ></confirmation-dialog>
    <v-snackbar
      v-model="createUserSuccessSnackbar"
      color="success"
      timeout="3000"
      min-width="auto"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ $icons.mdiCheck }}</v-icon>
        <span>{{ createUserSuccessText }}</span>
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="updateUserSuccessSnackbar"
      color="success"
      timeout="3000"
      min-width="auto"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ $icons.mdiCheck }}</v-icon>
        <span>{{ updateUserSuccessText }}</span>
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="deleteUserSuccessSnackbar"
      color="success"
      timeout="3000"
      min-width="auto"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ $icons.mdiCheck }}</v-icon>
        <span>{{ deleteUserSuccessText }}</span>
      </div>
    </v-snackbar>
    <confirmation-dialog
      v-model="sendActionMailDialog"
      :title="sendActionMailDialogTitle"
      :text="sendActionMailDialogText"
      :cancel="sendActionMailDialogCancelText"
      :confirm="sendActionMailDialogConfirmText"
      @cancel="resetSendActionMailDialog"
      @confirm="confirmSendActionMail"
    ></confirmation-dialog>
    <v-snackbar
      v-model="sendActionMailSuccessSnackbar"
      color="success"
      timeout="3000"
      min-width="auto"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ $icons.mdiCheck }}</v-icon>
        <span>{{ sendActionMailSuccessText }}</span>
      </div>
    </v-snackbar>
    <v-dialog
      width="600"
      v-model="addUserDialog"
    >
      <v-card
        class="pa-4"
      >
        <v-card-title
          v-text="upsertUserDialogTitle"
          class="mb-2"
        ></v-card-title>
        <v-card-text>
          <form-builder
            :config="userForm.config"
            :data="userForm.data"
            :errors="userForm.errors"
            :rules="userForm.rules"
            @reset="handleReset"
            @cancel="handleCancel"
            @submit="handleSubmit"
            ref="form"
            :form-name="formName"
          ></form-builder>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import FormBuilder from '@/components/form/FormBuilder'
import PageHeader from '@/components/PageHeader'

import NotificationState from '@/types/NotificationState'
import i18n from '@/mixins/i18n.mixin'
import Page from '@/mixins/page.mixin'

import Copy from '@/components/Copy'
import DataTable from '@/components/DataTable'
import FormattedDate from '@/components/FormattedDate'

import { required } from '@/helpers/validators.helpers'
import { shortenUuid } from '@/helpers/utility.helper'
import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'Users',
  mixins: [
    i18n,
    Page
  ],
  components: {
    ConfirmationDialog,
    Copy,
    FormBuilder,
    PageHeader,
    DataTable,
    FormattedDate
  },
  data () {
    return {
      deleteDialog: false,
      deleteUserItem: null,
      deleteUserSuccessSnackbar: false,
      sendActionMailDialog: false,
      sendActionMailItem: null,
      sendActionMailSuccessSnackbar: false,
      updateUserItem: null,
      updateUserSuccessSnackbar: false,
      createUserSuccessSnackbar: false,
      primaryField: 'uuid',
      searchTerm: '',
      lastError: -1,
      addUserDialog: false,
      addUserDialogOpened: 0,
      userForm: {
        config: [
          [
            [
              {
                name: 'username',
                component: 'v-text-field',
                props: {
                  required: true
                }
              },
              {
                name: 'email',
                component: 'v-text-field',
                type: 'email',
                props: {
                  required: true
                }
              },
              {
                name: 'firstName',
                component: 'v-text-field',
                props: {
                  required: true
                }
              },
              {
                name: 'lastName',
                component: 'v-text-field',
                props: {
                  required: true
                }
              },
              {
                name: 'group',
                component: 'v-select',
                props: {
                  options: [],
                  required: true
                }
              }
            ]
          ]
        ],
        data: {
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          group: ''
        },
        errors: {
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          group: ''
        },
        rules: {
          username: [
            required
          ],
          email: [
            required
          ],
          firstName: [
            required
          ],
          lastName: [
            required
          ],
          group: [
            required
          ]
        }
      },
      formName: 'addUserForm'
    }
  },
  created () {
    if (this.isAdmin) {
      this.fetchUserData()
    }
  },
  methods: {
    ...mapActions('keycloak', ['getUsers', 'createUser', 'updateUser', 'deleteUser', 'executeActionsEmail', 'getGroups', 'fetchUserData']),
    handleReset (formField) {
      this.$set(this.userForm.errors, formField, '')
    },
    handleCancel () {
      this.addUserDialog = false
    },
    handleSubmit (data) {
      if (this.updateUserItem === null) {
        const formData = Object.assign({
          createdTimestamp: (new Date()).getTime(),
          enabled: true,
          totp: false,
          emailVerified: false,
          disableableCredentialTypes: [],
          requiredActions: [
            'UPDATE_PASSWORD'
          ],
          notBefore: 0,
          access: {
            manageGroupMembership: true,
            view: true,
            mapRoles: true,
            impersonate: true,
            manage: true
          },
          realmRoles: []
        }, data)

        this.createUser({ formData, callee: this._uid })
          .then((response) => {
            if (response) {
              this.createUserSuccessSnackbar = true
            }
          })
      } else {
        this.updateUser({ userId: this.updateUserItem.id, formData: data, callee: this._uid })
          .then((response) => {
            if (response) {
              this.updateUserSuccessSnackbar = true
            }
          })
      }
    },
    sendActionMailHandler (item) {
      this.sendActionMailItem = item
      this.sendActionMailDialog = true
    },
    deleteHandler (item) {
      this.deleteUserItem = item
      this.deleteDialog = true
    },
    updateHandler (item) {
      this.updateUserItem = item
      const config = this.userForm.config

      const field = config[0][0].find(c => c.name === 'username')

      field.props.disabled = true

      Object.keys(this.userForm.data)
        .forEach((field) => {
          this.$set(this.userForm.data, field, item[field])
        })
      this.formName = 'editUserForm'
      this.addUserDialog = true
    },
    searchHandler (term) {
      this.searchTerm = term
    },
    isSendActionMailButtonDisabled (item) {
      return item.requiredActions.indexOf('UPDATE_PASSWORD') < 0 ||
        item.email === this.email
    },
    isDeleteButtonDisabled (item) {
      return item.email === this.email
    },
    isUpdateButtonDisabled (item) {
      return item.email === this.email
    },
    resetDeleteDialog () {
      this.deleteUserItem = null
    },
    confirmDelete () {
      this.deleteUser(this.deleteUserItem)
        .then((response) => {
          if (response) {
            this.deleteUserItem = null
            this.deleteDialog = false
            this.deleteUserSuccessSnackbar = true
          }
        })
    },
    resetSendActionMailDialog () {
      this.sendActionMailItem = null
    },
    confirmSendActionMail () {
      this.executeActionsEmail(this.sendActionMailItem)
        .then(() => {
          this.sendActionMailSuccessSnackbar = true
          this.sendActionMailItem = null
          this.sendActionMailDialog = false
        })
    },
    shortenUuid
  },
  computed: {
    ...mapGetters('keycloak', ['email', 'groups', 'isAdmin']),
    ...mapGetters('keycloak', {
      keycloakUsers: 'users'
    }),
    ...mapGetters('errors', ['errors']),
    users () {
      return this.keycloakUsers.map((user) => {
        user.createdOn = (new Date(user.createdTimestamp)).toISOString()
        user.groupName = this.$tp(`components.addUserForm.groups.${user.groupName}`)
        user.fullName = `${user.lastName}, ${user.firstName}`
        return user
      })
    },
    dataTable () {
      return {
        headers: this.headers,
        items: this.users,
        showSelect: false,
        itemKey: this.primaryField,
        sortBy: 'username',
        disablePagination: true,
        hideDefaultFooter: true,
        search: this.searchTerm
      }
    },
    headers () {
      return [
        { value: 'id', sortable: false },
        { value: 'username' },
        { value: 'email' },
        // { value: 'firstName' },
        // { value: 'lastName' },
        { value: 'fullName' },
        { value: 'groupName' },
        { value: 'createdOn' },
        { value: 'actions', sortable: false, align: 'end' }
      ]
    },
    actions () {
      return [
        { icon: this.$icons.mdiEmailOutline, handler: this.sendActionMailHandler, tooltip: this.sendActionMailButtonTooltip, disabled: this.isSendActionMailButtonDisabled },
        { icon: this.$icons.mdiPencil, handler: this.updateHandler, tooltip: this.updateButtonTooltip, disabled: this.isUpdateButtonDisabled },
        { icon: this.$icons.mdiDelete, handler: this.deleteHandler, tooltip: this.deleteButtonTooltip, disabled: this.isDeleteButtonDisabled }
      ]
    },
    searchAction () {
      return {
        label: this.$tp('components.management.table.search.label'),
        hint: this.$tp('components.management.table.search.hint'),
        handler: this.searchHandler
      }
    },
    sendActionMailButtonTooltip () {
      return this.$tp('components.management.table.actions.sendActionMail')
    },
    deleteButtonTooltip () {
      return this.$tp('components.management.table.actions.delete')
    },
    deleteDialogTitle () {
      return this.$tp('components.management.dialogs.delete.title')
    },
    deleteDialogText () {
      if (this.deleteUserItem !== null) {
        return this.$t('pages.users.components.management.dialogs.delete.text', { username: this.deleteUserItem.username })
      } else {
        return ''
      }
    },
    deleteDialogCancelText () {
      return this.$tp('components.management.dialogs.delete.cancelButtonText')
    },
    deleteDialogConfirmText () {
      return this.$tp('components.management.dialogs.delete.deleteButtonText')
    },
    deleteUserSuccessText () {
      return this.$tp('components.management.snackbars.deleteUserSuccessText')
    },
    createUserSuccessText () {
      return this.$tp('components.management.snackbars.createUserSuccessText')
    },
    updateButtonTooltip () {
      return this.$tp('components.management.table.actions.update')
    },
    updateUserSuccessText () {
      return this.$tp('components.management.snackbars.updateUserSuccessText')
    },
    sendActionMailDialogTitle () {
      return this.$tp('components.management.dialogs.sendActionMail.title')
    },
    sendActionMailDialogText () {
      if (this.sendActionMailItem !== null) {
        return this.$t('pages.users.components.management.dialogs.sendActionMail.text', { username: this.sendActionMailItem.username })
      } else {
        return ''
      }
    },
    sendActionMailDialogCancelText () {
      return this.$tp('components.management.dialogs.sendActionMail.cancelButtonText')
    },
    sendActionMailDialogConfirmText () {
      return this.$tp('components.management.dialogs.sendActionMail.deleteButtonText')
    },
    sendActionMailSuccessText () {
      return this.$tp('components.management.snackbars.sendActionMailSuccessText')
    },
    createUserButtonText () {
      return this.$tp('components.management.buttons.createUserButtonText')
    },
    upsertUserDialogTitle () {
      return this.updateUserItem !== null
        ? this.$tp('components.management.dialogs.update.title')
        : this.$tp('components.management.dialogs.create.title')
    },
    heading () {
      return this.$tp('components.management.heading')
    }
  },
  watch: {
    users () {
      this.addUserDialog = false
    },
    errors (newValue) {
      const latestError = newValue.map((error, i) => {
        return {
          ...error,
          originalIndex: i
        }
      }).reverse()
        .find((error) => {
          return error.callee === this._uid && error.notificationState !== NotificationState.SHOWN
        })
      if (latestError && latestError.originalIndex > this.lastError) {
        this.lastError = latestError.originalIndex
        const field = latestError.error.field
        this.$set(this.userForm.errors, field, latestError.error.errorMessage)
      }
    },
    groups (newValue) {
      const groupsItems = newValue.map((group) => {
        return {
          label: this.$tp(`components.addUserForm.groups.${group.name}`),
          value: group.id
        }
      })

      const i = this.userForm.config[0][0].findIndex((field) => {
        return field.name === 'group'
      })
      this.$set(this.userForm.config[0][0][i].props, 'options', groupsItems)
    },
    addUserDialog (newValue) {
      if (!newValue) {
        Object.keys(this.userForm.data)
          .forEach((field) => {
            this.userForm.data[field] = ''
          })
        if (this.$refs.form) {
          this.$refs.form.reset()
        }
        this.updateUserItem = null
        this.formName = 'addUserForm'
      }
    },
    deleteUserDialog (newValue) {
      if (!newValue) {
        this.deleteUserItem = null
      }
    },
    updateUserItem (newValue) {
      if (newValue === null) {
        if (this.$refs.form) {
          this.$refs.form.reset()
        }
        const config = this.userForm.config

        const field = config[0][0].find(c => c.name === 'username')

        field.props.disabled = false
      }
    }
  }
}
</script>
